import React from "react";
import "../styles/style.css";
import { IMAGE } from "../assests/images";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-container">
      <div className="container p-5 pb-0">
        <div className="d-lg-flex justify-content-center gap-8">
          <div className="col-lg-8">
            <img src={IMAGE.FooterLogo} alt="Logo" />
            <div className="mt-4 footersubtext">
              <ul className="nav flex-column align-items-start gap-2">
                <li><div className="text-decoration-none text-light d-flex">
                  <img src={IMAGE.callIcon} alt="call" className="img-fluid me-3" style={{ height: 30, width: 30 }} /><p>(629) 000-0129</p>
                </div></li>
                <li>   <div className="text-decoration-none text-light d-flex">
                  <img src={IMAGE.smsIcon} alt="call" className="img-fluid me-3" style={{ height: 30, width: 30 }} /><p>pricepeek@example.com</p>
                </div></li>
                <li> <div className="text-decoration-none text-light d-flex">
                  <img src={IMAGE.locationIcon} alt="call" className="img-fluid me-3" style={{ height: 30, width: 30 }} /><p>4140 Parker Rd. Allentown, New Mexico 31134</p>
                </div></li>
              </ul>
            </div>
          </div>
          <div className="footertext col-lg-2" onClick={() => navigate("/")}>
            <ul className="nav flex-lg-column flex-row align-items-start gap-lg-4 gap-2">
              <li><a href="#Home" className="text-decoration-none text-light">
                Home
              </a></li>
              <li>   <a href="#Features" className="text-decoration-none text-light">
                Features
              </a></li>
              <li> <a href="#Review" className="text-decoration-none text-light">
                Reviews
              </a></li>
              <li>
                <a href="#Download" className="text-decoration-none text-light">
                  Download App
                </a>
              </li>
            </ul>
          </div>
          <div className="footertext col-lg-2 pt-lg-0 pt-2">
            <ul className="nav flex-lg-column flex-row align-items-start gap-lg-4 gap-2">
              <li onClick={() => navigate("/terms")}>
                <a href="/terms" className="text-decoration-none text-light">Terms & Condition</a>
              </li>
              <li onClick={() => navigate("/privacy")}>
                <a href="/privacy" className="text-decoration-none text-light">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-copyright align-items-center d-lg-flex justify-content-between mt-lg-0 mt-2 pt-lg-4 pb-lg-4 pt-2 pb-2">
          <p className="m-0">© 2024 PricePeek. All Rights Reserved.</p>
          <div className="social-icons text-center d-lg-flex justify-content-end gap-5">
            <ul className="nav flex-row align-items-start gap-lg-5 gap-3 mt-lg-0 mt-4">
              <li className="text-success me-lg-3">Facebook <img src={IMAGE.RightArrow} alt="right-arrow" /></li>
              <li className="text-secondary me-lg-3">Instagram <img src={IMAGE.RightArrowGray} alt="right-arrow" /></li>
              <li className="text-secondary">LinkedIn <img src={IMAGE.RightArrowGray} alt="right-arrow" /></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
